import UtilityHelper from '../utility-helper/utility-helper';
export default class AppDrawerLayoutElement extends HTMLElement {
  constructor() {
    super();
    this._drawer = null;
  }

  connectedCallback() {
    this._click = UtilityHelper.clickEvent;
    setTimeout(() => {
      this._setDrawerElement();

      this._setToggleListener();

      this._setLogoListener();
    }, 500);
  }

  _setDrawerElement() {
    this._drawer = this.querySelector('app-drawer');
  }

  _setToggleListener() {
    $(this).on(this._click, '[data-toggle]', event => {
      this._onToggleClick(event);
    });
  }

  _setLogoListener() {
    $(this).on(this._click, '[data-logo]', event => {
      location.href = this.home;
    });
  }

  _onToggleClick(event) {
    this._drawer.toggle();
  }

  get home() {
    var home = this.getAttribute('home');
    if (home) return home;else return '/';
  }

}
customElements.define('app-drawer-layout', AppDrawerLayoutElement);