export default class PrintPageElement extends HTMLElement {
  constructor() {
    super();
    this._iFrame = null;
    this._onFrameLoaded = this._onFrameLoaded.bind(this);
    this._url = null;
  }

  connectedCallback() {
    if (this.notifySelector) this._setNotificationElement();
  }

  disconnectedCallback() {
    this._removeEvent();

    this._removeIFrame();
  }

  _setNotificationElement() {
    setTimeout(() => {
      this._notificationElement = document.querySelector(this.notifySelector);
    }, 750);
  }

  _setEvent() {
    var iFrame = this._iFrame;
    var onFrameLoaded = this._onFrameLoaded;
    iFrame.addEventListener('load', onFrameLoaded);
  }

  _removeEvent() {
    var iFrame = this._iFrame;
    var onFrameLoaded = this._onFrameLoaded;
    if (iFrame) iFrame.removeEventListener('load', onFrameLoaded);
  }

  _removeIFrame() {
    var iFrame = this._iFrame;
    if (iFrame) iFrame.remove();
  }

  _onFrameLoaded() {
    this._printPage();
  }

  _print(url) {
    this._addIframeToPage(url);
  }

  _printPage() {
    window.frames[this.frameId].focus();
    window.frames[this.frameId].print();
  }

  _addIframeToPage(url) {
    var iframe = this._iFrame;

    if (!iframe) {
      this._url = url;
      iframe = this._getIFrame(url);
      this._iFrame = iframe[0];
      $("body").append(iframe);

      this._setEvent();
    } else {
      if (url !== this._lastUrl) {
        this._url = url;
        iframe.setAttribute("src", url);
      } else {
        this._printPage();
      }
    }
  }

  _getIFrame(url) {
    return $('<iframe id="' + this.frameId + '" name="' + this.frameId + '" src=' + url + ' style="position:absolute;top:0px; left:0px;width:0px; height:0px;border:0px;overfow:none; z-index:-1"></iframe>');
  }

  _showNotification(message) {
    if (this._notificationElement) this._notificationElement.show(message);
  }

  get frameId() {
    var val = this.getAttribute('frame-id');
    if (val) return val;else return 'print-page';
  }

  get notifySelector() {
    return this.getAttribute('notify-selector');
  }

  get url() {
    return this.getAttribute('url');
  }

  set url(val) {
    this.setAttribute('url', val);
  }

  print(url) {
    if (!url) url = this.url;
    if (url) this._print(url);
  }

}
customElements.define('print-page', PrintPageElement);