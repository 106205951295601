export default class GoogleMapElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this._markers = [];

    this._attachMapElement();

    this._createMap();

    this._setInfoWindow();

    this._setMarkers();

    if (this._markers.lengh > 0) {
      this._fitMarkersToMap();
    }
  }

  disconnectedCallback() {
    this.clearMarkers();
    this._map = null;
    this._infowindow = null;

    this._mapNode.remove();
  }

  _attachMapElement() {
    this._mapNode = document.createElement("div");
    this.appendChild(this._mapNode);
  }

  _setInfoWindow() {
    this._infowindow = new google.maps.InfoWindow({
      content: ''
    });
  }

  _createMap() {
    var lat = this.latitude;
    var lng = this.longitude;
    var zoom = this.zoom;
    this._map = new google.maps.Map(this._mapNode, {
      center: {
        lat: lat,
        lng: lng
      },
      zoom: zoom
    });
  }

  _setMarkers() {
    if (this.location) {
      this._setMarker(this.location);
    } else if (this.locations.length > 0) {
      this.locations.forEach(location => {
        this._setMarker(location);
      });
    }
  }

  _setMarker(location) {
    var marker = new google.maps.Marker({
      position: {
        lat: location.latitude,
        lng: location.longitude
      },
      map: this._map,
      title: location.name
    });
    marker.location = location;

    this._markers.push(marker);

    marker.addListener('click', () => {
      this._openInfoWindow(marker);
    });
  }

  _fitMarkersToMap() {
    var bounds = new google.maps.LatLngBounds();
    var markers = this._markers;

    if (markers.length > 0) {
      for (var i = 0; i < markers.length; i++) {
        bounds.extend(markers[i].getPosition());
      }

      this._map.fitBounds(bounds);
    }

    this._map.setZoom(this.zoom);
  }

  _setMapOnAll(map) {
    var markers = this._markers;

    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  }

  _clearMarkers() {
    this._setMapOnAll(null);
  }

  _openInfoWindow(marker) {
    var content = this._getContentString(marker.location);

    this._infowindow.setContent(content);

    this._infowindow.open(this._map, marker);
  }

  _getContentString(location) {
    if (location.content) return location.content;
    var html = '<b>' + location.name + '</b><br>' + location.street + '<br>' + location.city + ', ' + location.regionCode + ' ' + location.zipCode + '<br><br>';

    if (location.label) {
      html += location.label;
    }

    return html;
  }

  get zoom() {
    var zoom = this.getAttribute('zoom');
    if (zoom) return parseInt(zoom);else return 15;
  }

  get latitude() {
    var lat = this.getAttribute('latitude');
    if (lat) return parseFloat(lat);else return 37.419857;
  }

  get longitude() {
    var lng = this.getAttribute('longitude');
    if (lng) return parseFloat(lng);else return -122.078827;
  }

  get location() {
    var strLocation = this.getAttribute('marker-location');

    if (strLocation) {
      return JSON.parse(strLocation);
    } else {
      return null;
    }
  }

  get locations() {
    var strLocations = this.getAttribute('marker-locations');

    if (strLocations) {
      return JSON.parse(strLocations);
    } else {
      return [];
    }
  }

  clearMarkers() {
    this._clearMarkers();

    this._markers = [];
  }

  addMarkers(locations) {
    if (locations && this.location.length) {
      locations.forEach(location => {
        this._setMarker(location);
      });

      this._fitMarkersToMap();
    }
  }

  addMarker(location) {
    this._setMarker(location);

    this._fitMarkersToMap();
  }

  hideMarkers() {
    this._setMapOnAll(this._map);
  }

  showMarkers() {
    this._setMapOnAll(this._map);

    this._fitMarkersToMap();
  }

}
customElements.define('google-map', GoogleMapElement);