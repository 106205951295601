export default class TransitionHelper {
  static transition(element, options, callback) {
    $.transition = $.transition || $.transit;
    if (!(element instanceof jQuery)) element = $(element);
    options = options || {};

    if (options === {}) {
      options.duration = 300;
      options.preset = 'fadeIn';
    }

    if (options.preset === 'none') {
      element.hide();
      return;
    }

    element.transition(options, function () {
      if (callback) {
        callback.call(element[0]);
      }
    });
  }

}