import UtilityHelper from '../utility-helper/utility-helper';
export default class CarouselIndicatorsElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.element = $(this);
    this.fire = UtilityHelper.fireEvent;
    this._tap = UtilityHelper.tap;

    this._setInternalProperties();

    this._setCarousel();

    this._attachToDom();

    this._setEvents();
  }

  _setInternalProperties() {
    this._activeIndex = 0;
    this._eventTriggered = false;
  }

  _setEvents() {
    $(document).on('ui-carousel-slide', this._onSlideChange.bind(this));
    this.element.on(this._tap, 'i', this._onClick.bind(this));
  }

  _setCarousel() {
    var id = this.carouselId;
    this._carousel = document.querySelector('#' + id);
  }

  _slideCarousel(index) {
    var carousel = this._carousel;
    this._eventTriggered = true;
    if (carousel) carousel.slide(index);
  }

  _onClick(event) {
    var index = event.target.getAttribute('index');
    index = parseInt(index);

    this._slideCarousel(index);

    this._updateItems(index);
  }

  _updateItems(index) {
    this.activeIndex = index;

    this._items.removeClass('active');

    var selector = 'i[index="' + index + '"]';
    var item = this.element.find(selector);
    item.addClass('active');
  }

  _onSlideChange(event) {
    if (this._eventTriggered) this._eventTriggered = false;else {
      var detail = event.detail;

      if (detail.id === this.carouselId) {
        var index = detail.slideIndex;

        this._updateItems(index);
      }
    }
  }

  _attachToDom() {
    var length = this.length;
    var dom = '';

    for (var i = 0; i < length; i++) {
      if (i === 0) {
        dom += '<i class="material-icons active" index="' + i + '">fiber_manual_record</i>';
      } else {
        dom += '<i class="material-icons" index="' + i + '">fiber_manual_record</i>';
      }
    }

    this.element.append(dom);
    this._items = this.element.find("i");
  }

  get length() {
    var length = this.getAttribute('length');
    if (length) return parseInt(length);else return 0;
  }

  get carouselId() {
    return this.getAttribute('carousel-id');
  }

  set carouselId(val) {
    this.setAttribute('carousel-id', val);
  }

  get activeIndex() {
    return this._activeIndex;
  }

  set activeIndex(val) {
    this._activeIndex = val;
  }

}
customElements.define('carousel-indicators', CarouselIndicatorsElement);