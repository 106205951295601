import UtilityHelper from '../utility-helper/utility-helper';
export default class MdInputElement extends HTMLElement {
  constructor() {
    super();
    this._css = '';
    this._label = null;
    this._leftIcon = null;
    this._rightIcon = null;
    this._placeholder = '';
    this._id = null;
    this._name = null;
    this._defaultValue = '';
    this._required = false;
    this._type = 'text';
    this._disableKeyPressEvent = false;
    this._iconPointer = false;
    this._readOnly = false;
    this._disabled = false;
    this._onKeyPress = this._onKeyPress.bind(this);
    this._onFocus = this._onFocus.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onIconClick = this._onIconClick.bind(this);
  }

  static get observedAttributes() {
    return ['value', 'placeholder', 'readonly', 'disabled'];
  }

  connectedCallback() {
    this.fire = UtilityHelper.fireEvent;
    this.click = UtilityHelper.clickEvent;

    this._setProperties();

    var dom;

    if (this._leftIcon) {
      dom = this._getDomWithLeftIconString(this._type, this._label, this._css, this._placeholder, this._id, this._name, this._defaultValue, this._required, this._leftIcon);
    } else if (this._rightIcon) {
      dom = this._getDomWithRightIconString(this._type, this._label, this._css, this._placeholder, this._id, this._name, this._defaultValue, this._required, this._rightIcon);
    } else {
      dom = this._getDomString(this._type, this._label, this._css, this._placeholder, this._id, this._name, this._defaultValue, this._required);
    }

    this._attachToDom(dom);

    this._setEvents();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.input && oldValue) {
      this.input.setAttribute(name, newValue);

      if ((name === 'readonly' || name === 'disabled') && (newValue === 'false' || newValue === false)) {
        this.input.removeAttribute(name);
      }
    }
  }

  disconnectedCallback() {
    this._removeEvents();
  }

  _setProperties() {
    var defaultId = "input-" + UtilityHelper.id(4);
    var leftIcon = this.getAttribute('left-icon');
    var rightIcon = this.getAttribute('right-icon');
    var css = this.getAttribute('input-class');
    var label = this.getAttribute('label');
    var placeholder = this.getAttribute('placeholder');
    var iconPointer = this.getAttribute('icon-pointer');
    var id = this.dataset.id;
    var name = this.dataset.name;
    var value = this.getAttribute('value');
    var disableKeyPressEvent = this.getAttribute('disable-keypress-event');
    var required = this.getAttribute('required');
    var type = this.getAttribute('type');

    if (leftIcon) {
      this._leftIcon = leftIcon;
    } else if (rightIcon) {
      this._rightIcon = rightIcon;
    }

    if (css) this._css = css;
    if (label) this._label = label;
    if (placeholder) this._placeholder = placeholder;
    if (id) this._id = id;else this._id = defaultId;
    if (name) this._name = name;else this._name = defaultId;
    if (value) this._defaultValue = value;
    if (disableKeyPressEvent) this._disableKeyPressEvent = true;
    if (required) this._required = true;
    if (type) this._type = type;
    if (iconPointer) this._iconPointer = true;
    var readOnly = this.getAttribute('readonly');
    if (readOnly) this._readOnly = true;
    var disabled = this.getAttribute('disabled');
    if (disabled) this._disabled = true;
  }

  _setEvents() {
    var onKeyPress = this._onKeyPress;
    var onFocus = this._onFocus;
    var onBlur = this._onBlur;
    var onChange = this._onChange;
    var onIconClick = this._onIconClick;
    var input = this.input;

    if (!this.disableKeyPressEvent) {
      input.addEventListener('keypress', onKeyPress);
    }

    input.addEventListener('focus', onFocus);
    input.addEventListener('blur', onBlur);
    input.addEventListener('change', onChange);

    if (this.icon) {
      this.icon.addEventListener(this.click, onIconClick);
    }
  }

  _removeEvents() {
    var onKeyPress = this._onKeyPress;
    var onFocus = this._onFocus;
    var onBlur = this._onBlur;
    var onChange = this._onChange;
    var onIconClick = this._onIconClick;
    var input = this.input;
    if (!input) return;

    if (!this.disableKeyPressEvent) {
      input.removeEventListener('keypress', onKeyPress);
    }

    input.removeEventListener('focus', onFocus);
    input.removeEventListener('blur', onBlur);
    input.removeEventListener('change', onChange);

    if (this.icon) {
      this.icon.removeEventListener(this.click, onIconClick);
    }
  }

  _getDomString(type, label, css, placeholder, id, name, value, required) {
    var dom = '';
    var required = '';
    var readonly = '';
    var disabled = '';
    if (this._required) required = 'required';
    if (this._readOnly) readonly = ' readonly ';
    if (this._disabled) disabled = ' disabled';
    if (!placeholder) placeholder = ' ';

    if (label) {
      dom = '<div class="input-group ' + css + '"><input ' + required + readonly + disabled + ' type="' + type + '" placeholder="' + placeholder + '" id="' + id + '" name="' + name + '" value="' + value + '"><span class="highlight"></span><span class="bar"></span><label>' + label + '</label></div>';
    } else {
      dom = '<div class="input-group ' + css + '"><input ' + required + readonly + disabled + ' type="' + type + '" placeholder="' + placeholder + '" id="' + id + '" name="' + name + '" value="' + value + '"><span class="highlight"></span><span class="bar"></span></div>';
    }

    return dom;
  }

  _getDomWithRightIconString(type, label, css, placeholder, id, name, value, required, icon) {
    css = 'icon ' + css;

    var innerDom = this._getDomString(type, label, css, placeholder, id, name, value, required);

    var dom = '<div class="flex-container">' + innerDom + '<i class="material-icons">' + icon + '</i></div>';
    return dom;
  }

  _getDomWithLeftIconString(type, label, css, placeholder, id, name, value, required, icon) {
    css = 'icon ' + css;

    var innerDom = this._getDomString(type, label, css, placeholder, id, name, value, required);

    var dom = '<div class="flex-container"><i class="material-icons">' + icon + '</i>' + innerDom + '</div>';
    return dom;
  }

  _attachToDom(dom) {
    var $dom = $(dom);
    $(this).append($dom);
    this.input = this.querySelector('input');

    if (this._iconPointer) {
      this.icon = this.querySelector('i');

      if (this.icon) {
        this.icon.style.cursor = 'pointer';
      }
    }
  }

  _onKeyPress(event) {
    if (event.charCode == 13 || event.charCode == 0) {
      var target = event.target;
      var value = target.value;
      event.preventDefault();
      this.fire('md-input-enter', {
        value: value,
        target: this,
        name: this._name,
        id: this._id
      });
    }
  }

  _onIconClick(event) {
    var value = this.value;
    this.fire('md-input-click', {
      value: value,
      target: this,
      name: this._name,
      id: this._id
    });
  }

  _onFocus(event) {
    this.fire('md-input-focus', {
      target: this,
      name: this._name,
      id: this._id
    });
  }

  _onBlur(event) {
    this.fire('md-input-blur', {
      target: this,
      name: this._name,
      id: this._id
    });
  }

  _onChange(event) {
    this.fire('md-input-change', {
      value: event.target.value,
      target: this,
      name: this._name,
      id: this._id
    });
  }

  get value() {
    return this.input.value;
  }

  set value(val) {
    this.input.value = val;
    this.setAttribute('value', val);
  }

  get readonly() {
    return this.input.getAttribute('readonly');
  }

  set readonly(val) {
    if (val) {
      this.input.setAttribute('readonly', 'true');
    } else {
      this.input.removeAttribute('readonly');
    }
  }

  get disabled() {
    return this.input.getAttribute('disabled');
  }

  set disabled(val) {
    if (val) {
      this.input.setAttribute('disabled', 'true');
    } else {
      this.input.removeAttribute('disabled');
    }
  }

  clear() {
    this.input.value = '';
  }

}
customElements.define('md-input', MdInputElement);