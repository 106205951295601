import UtilityHelper from '../utility-helper/utility-helper';
import '@polymer/paper-input/paper-input';
import '@polymer/paper-item/paper-item';
import { LitElement, html } from '@polymer/lit-element'; //import {html, render} from 'lit-html/lib/lit-extended'

const DISPLAY_FILTERS = {
  NONE: 'none',
  BLOCK: 'block'
};
const DIRECTION = {
  UP: 'up',
  DOWN: 'down'
};
const KEY_CODES = {
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  ENTER: 13,
  ESCAPE: 27
};

class MdAutocomplete extends LitElement {
  //// STATIC GETTERS
  static get properties() {
    return {
      items: {
        type: Array
      },
      label: {
        type: String,
        reflect: true
      },
      value: {
        type: String,
        reflect: true
      },
      wrapperDisplay: {
        type: String
      },
      closeButtonDisplay: {
        type: String
      }
    };
  } ////CONSTRUCTOR


  constructor() {
    super();
    this._currentIndex = -1;
    this._scrollIndex = 0;
    this._itemHeight = 36;
    this.items = [];
    this.wrapperDisplay = DISPLAY_FILTERS.NONE;
    this.closeButtonDisplay = DISPLAY_FILTERS.NONE;
    this._onSelect = this._onSelect.bind(this);
  } ///LIFECYCLE: connectedCallback


  connectedCallback() {
    this._textProp = this.textProperty;
    this._valueProp = this.valueProperty;
    this.fire = UtilityHelper.fireEvent;
    this.value = this.getAttribute('value');
    if (this.disabled) this._setDisabledAttribute(true);
  } ///LIFECYCLE: firstUpdated


  firstUpdated(changedProperties) {
    this.suggestionsWrapper = this.shadowRoot.querySelector('#suggestionsWrapper');
    this.input = this.shadowRoot.querySelector('paper-input');
  } //// EVENT HANDLERS


  _onKeypress(event) {
    var keyCode = event.which || event.keyCode;

    switch (keyCode) {
      case KEY_CODES.DOWN_ARROW:
        this._moveHighlighted(DIRECTION.DOWN);

        break;

      case KEY_CODES.UP_ARROW:
        this._moveHighlighted(DIRECTION.UP);

        break;

      case KEY_CODES.ENTER:
        this._keyenter();

        break;

      case KEY_CODES.ESCAPE:
        this._clear();

        break;
      // For left and right arrow, component should do nothing

      case KEY_CODES.LEFT_ARROW: // fall through

      case KEY_CODES.RIGHT_ARROW:
        break;

      default:
        this._onInputChange(event);

    }
  }

  _onClear(event) {
    this._clear();
  }

  _onSelect(item) {
    this._setSelection(item);
  }

  _onInputChange(event) {
    var value = event.target.value;
    var minLength = this.minLength;
    var option = {
      text: value,
      value: value
    };

    if (value && value.length >= minLength) {
      this.closeButtonDisplay = DISPLAY_FILTERS.BLOCK;

      this._fireEvent('change', option);
    } else {
      this._reset();
    }
  } ////PRIVATE METHODS


  _clear() {
    if (this._option) this.value = this._option.text;

    this._reset();
  }

  _reset() {
    this.items = [];
    this._currentIndex = -1;
    this._scrollIndex = 0;

    this._hide();
  }

  _show() {
    this.closeButtonDisplay = DISPLAY_FILTERS.BLOCK;
    this.wrapperDisplay = DISPLAY_FILTERS.BLOCK;
  }

  _hide() {
    this.wrapperDisplay = DISPLAY_FILTERS.NONE;
    this.closeButtonDisplay = DISPLAY_FILTERS.NONE;
  }

  _setSelection(item) {
    var option = {
      text: item[this._textProp],
      value: item[this._valueProp]
    };
    this._option = option;

    this._clear();

    this._fireEvent('selected', option);
  }

  _getDomItems() {
    return this.shadowRoot.querySelectorAll('paper-item');
  }

  _setDisabledAttribute(prop) {
    if (prop) {
      this.input.disabled = true;
    } else {
      this.input.disabled = false;
    }
  }

  _keyenter() {
    var index = this._currentIndex;
    var item = this.items[index];

    this._setSelection(item);
  }

  _moveHighlighted(direction) {
    var items = this._getDomItems();

    ;

    if (items.length === 0) {
      return;
    }

    var numberOfItems = items.length - 1;
    var isFirstItem = this._currentIndex === 0;
    var isLastItem = this._currentIndex === numberOfItems;
    var isNoItemHighlighted = this._currentIndex === -1;

    if ((isNoItemHighlighted || isFirstItem) && direction === DIRECTION.UP) {
      this._currentIndex = numberOfItems;
    } else if (isLastItem && direction === DIRECTION.DOWN) {
      this._currentIndex = 0;
    } else {
      var modifier = direction === DIRECTION.DOWN ? 1 : -1;
      this._currentIndex = this._currentIndex + modifier;
    }

    var highlightedOption = this.items[this._currentIndex];
    var highlightedItem = items[this._currentIndex];

    this._removeActive(items);

    highlightedItem.classList.add('active');
    highlightedItem.setAttribute('aria-selected', 'true'); //this._setHighlightedSuggestion(highlightedOption, highlightedItem.id);

    this._scroll(direction);
  }

  _scroll(direction) {
    var newScrollValue, isSelectedOutOfView;
    var viewIndex = this._currentIndex - this._scrollIndex; // This happens only when user switch from last item to first one

    var isFirstItemAndOutOfView = this._currentIndex === 0 && viewIndex < 0; // This happens only when user switch from first or no item to last one

    var isLastItemAndOutOfView = this._currentIndex === this.items.length - 1 && viewIndex >= this.maxViewableItems;

    if (isFirstItemAndOutOfView && direction === DIRECTION.DOWN) {
      newScrollValue = 0;
      isSelectedOutOfView = true;
    } else if (isLastItemAndOutOfView && direction === DIRECTION.UP) {
      newScrollValue = this.items.length - this.maxViewableItems;
      isSelectedOutOfView = true;
    } else if (direction === DIRECTION.UP) {
      newScrollValue = this._scrollIndex - 1;
      isSelectedOutOfView = viewIndex < 0;
    } else {
      newScrollValue = this._scrollIndex + 1;
      isSelectedOutOfView = viewIndex >= this.maxViewableItems;
    } // Only when the current active element is out of view, we need to move the position of the scroll


    if (isSelectedOutOfView) {
      this._scrollIndex = newScrollValue;
      this.suggestionsWrapper.scrollTop = this._scrollIndex * this._itemHeight;
    }
  }

  _removeActive(items) {
    [].slice.call(items).forEach(function (item) {
      item.classList.remove('active');
      item.setAttribute('aria-selected', 'false');
    });
  }

  _fireEvent(event, option) {
    var id = this.id;
    this.fire('md-autocomplete-' + event, {
      id: id,
      value: option.value,
      target: this,
      option: option
    });
  } ///// PUBLIC GETTERS/SETTERS


  get suggestions() {
    return this.items;
  }

  set suggestions(arr) {
    this.items = arr;

    this._show();
  }

  get selectedOption() {
    return this._option;
  }

  set selectedOption(val) {
    this._option = val;
    this.value = val.text;
    this.label = val.text;
  }

  get disabled() {
    var val = this.getAttribute('disabled');
    if (val) return val === 'true';else return false;
  }

  set disabled(val) {
    this.setAttribute('disabled', val);

    this._setDisabledAttribute(val);
  }

  get id() {
    return this.getAttribute('id');
  }

  set id(val) {
    this.setAttribute('id', val);
  }

  get minLength() {
    var val = this.getAttribute('min-length');
    if (val) return parseInt(val);else return 2;
  }

  set minLength(val) {
    this.setAttribute('min-length', val);
  }

  get maxViewableItems() {
    var val = this.getAttribute('max-viewable-items');
    if (val) return parseInt(val);else return 7;
  }

  set maxViewableItems(val) {
    this.setAttribute('max-viewable-items', val);
  }

  get textProperty() {
    var prop = this.getAttribute('text-property');
    if (prop) return prop;else return 'text';
  }

  get valueProperty() {
    var prop = this.getAttribute('value-property');
    if (prop) return prop;else return 'value';
  }

  get placeholder() {
    return this.label;
  }

  set placeholder(val) {
    if (this._option) {
      this._option.value = null;
      this._option.text = null;
    }

    this.value = '';
    this.label = val;
  }

  get selectedValue() {
    return this._option.value;
  }

  set selectedValue(val) {
    this._option.value = val;
    this.value = val;
  } ////RENDER


  render() {
    var value = this.value;
    if (!value) value = '';
    return html`
        <style>
           :host{
               display:block;
               position:relative;
               box-sizing: border-box;
               width:100%;
           }
           .input-wrapper{
               display:flex;
               align-items:center;
           }
           .paper-material{
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
           }
           #suggestionsWrapper{
               background-color: white;
               max-height: 252px;
               overflow-y: scroll;
               width: 100%;
               position: absolute;
               z-index: 1000;
           }
           paper-item {
             position: relative;
             line-height:18px;
             min-height:36px;
           }
           paper-input{
               flex:1;
               max-width:100%;
           }
           paper-item:hover{
             background:#eee;
             color:#333;
             cursor:pointer;
           }
           paper-item.active{
             background:#eee;
             color:#212121;
           }
           .button-clear{
               position:relative;
               padding:8px;
               cursor:pointer;
           }
           paper-ripple{
               height:40px;
           }
           svg{
               margin-bottom:-6px;
           }
           :host {
             --paper-input-container-focus-color: #2196f3;
           }
        </style>
        <div class="input-wrapper">
          <paper-input id="input" label="${this.label}" no-label-float @keyup="${this._onKeypress.bind(this)}" value="${value}"></paper-input>
          <div class="button-clear" style="display:${this.closeButtonDisplay};" @click=${this._onClear.bind(this)}>
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
             <paper-ripple center class="circle"></paper-ripple>
          </div>
        </div>
        <div class="paper-material" style="display:${this.wrapperDisplay};" id="suggestionsWrapper">
          ${this.items.map(item => html`
               <paper-item @click="${e => this._onSelect(item)}">
                 <div>${item[this._textProp]}</div>
                 <paper-ripple></paper-ripple>
               </paper-item>
               `)}
        </div>
        `;
  }

}

customElements.define('md-autocomplete', MdAutocomplete);