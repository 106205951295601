import './grid-item';
import './column-header';
import './grid-pagination';
import './page-info';
import './page-navigation';
export default class FlexGridElement extends HTMLElement {
  constructor() {
    super();
  }

}
customElements.define('flex-grid', FlexGridElement);