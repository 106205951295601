import UtilityHelper from '../utility-helper/utility-helper';
export default class FileDownloadElement extends HTMLElement {
  constructor() {
    super();
  }

  _sendNotification(message) {
    if (!this.notifySelector) return;
    var notification = document.querySelector(this.notifySelector);
    if (notification) notification.show(message);
  }

  _download(url, message) {
    this._sendNotification(message);

    setTimeout(() => {
      this._downloadUrl(url);
    }, 500);
  }

  _downloadUrl(url) {
    if (UtilityHelper.browser.webkit) {
      var link = document.createElement('a');
      link.href = url;

      if (link.download !== undefined) {
        link.download = url;
      }

      if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        link.dispatchEvent(event);
        return true;
      }

      if (url.indexOf('?') === -1) {
        url += '?download';
      }

      window.open(url, '_self');
    } else {
      if (url.indexOf('?') === -1) {
        url += '?download';
      }

      window.open(url);
    }
  }

  get message() {
    var val = this.getAttribute('message');
    if (val) return message;else return 'Downloading File...';
  }

  set message(val) {
    this.setAttribute('message', val);
  }

  get notifySelector() {
    return this.getAttribute('notify-selector');
  }

  get(url, message) {
    if (message === undefined) {
      message = this.message;
    }

    this._download(url, message);
  }

}
customElements.define('file-download', FileDownloadElement);