import UtilityHelper from '../utility-helper/utility-helper';
export default class MdRadioElement extends HTMLElement {
  constructor() {
    super();
    this._id = null;
    this._name = null;
    this._defaultChecked = false;
    this._required = false;
    this._disabled = false;
    this._label = null;
    this._value = null;
    this._onChange = this._onChange.bind(this);
  }

  static get observedAttributes() {
    return ['value', 'checked', 'readonly', 'disabled'];
  }

  connectedCallback() {
    this.fire = UtilityHelper.fireEvent;

    this._setProperties();

    this._attachDom();

    this._setEvents();
  }

  disconnectedCallback() {
    this._removeEvents();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.radio && oldValue) {
      this.radio.setAttribute(name, newValue);

      if ((name === 'readonly' || name === 'disabled') && (newValue === 'false' || newValue === false)) {
        this.radio.removeAttribute(name);
      }
    }
  }

  _setProperties() {
    var defaultId = "ck-" + UtilityHelper.id(4);
    var id = this.dataset.id;
    var name = this.dataset.name;
    var checked = this.getAttribute('checked');
    var required = this.getAttribute('required');
    var disabled = this.getAttribute('disabled');
    var value = this.getAttribute('value');
    if (id) this._id = id;else this._id = defaultId;
    if (name) this._name = name;else this._name = defaultId;
    if (checked) this._defaultChecked = true;
    if (disabled && disabled !== 'false') this._disabled = true;
    if (required) this._required = true;
    if (value) this._value = value;
  }

  _setEvents() {
    var onChange = this._onChange;
    var radio = this.radio;
    radio.addEventListener('change', onChange);
  }

  _removeEvents() {
    var onChange = this._onChange;
    var radio = this.radio;
    radio.removeEventListener('change', onChange);
  }

  _onChange(event) {
    this.fire('md-radio-change', {
      checked: event.target.checked,
      value: event.target.value,
      target: this,
      name: this._name,
      id: this._id
    });
  }

  _attachDom() {
    var id = this._id;
    var name = this._name;
    var label = this.label;
    var required = '';
    if (this._required) required = 'required';
    var checked = '';
    if (this._defaultChecked) checked = ' checked';
    var disabled = '';
    if (this._disabled) disabled = ' disabled ';
    var value = '';
    if (this._value) value = ' value="' + this._value + '"';
    var dom = '<input ' + required + ' type="radio" id="' + id + '" name="' + name + '"' + checked + disabled + value + '>';
    dom += '<label for="' + this._id + '">' + label + '</label>';
    var $dom = $(dom);
    $(this).append($dom);
    this.radio = this.querySelector('input');
    this._label = this.querySelector('label');
  }

  get checked() {
    return this.radio.checked;
  }

  set checked(val) {
    this.radio.checked = val;
  }

  get value() {
    return this.radio.value;
  }

  set value(val) {
    this.radio.value = val;
  }

  get label() {
    return this.getAttribute('label');
  }

  set label(val) {
    this.setAttribute('label', val);
    this._label.innerHTML = val;
  }

}
customElements.define('md-radio', MdRadioElement);