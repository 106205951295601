import UtilityHelper from '../utility-helper/utility-helper';
export default class SocialButtonsElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.click = UtilityHelper.clickEvent;

    this._setEvents();
  }

  disconnectedCallback() {
    this._removeEvents();
  }

  _setEvents() {
    $(this).on(this.click, '[data-id]', this._onClick.bind(this));
  }

  _removeEvents() {
    $(this).off(this.click, '[data-id]', this._onClick.bind(this));
  }

  _onClick(event) {
    var target = event.target;
    var id = target.dataset.id;

    switch (id) {
      case 'twitter':
        this._twitter();

        break;

      case 'facebook':
        this._facebook();

        break;

      case 'pinterest':
        this._pinterest();

        break;

      case 'google':
        this._google();

        break;

      case 'linkedin':
        this._linkedIn();

        break;

      case 'email':
        this._email();

        break;
    }
  }

  _twitter() {
    var url = encodeURI(this.url);
    var text = encodeURI(this.title);
    var URL = 'https://twitter.com/share?url=' + url + '&text=' + text;

    this._launchWindow(URL);
  }

  _facebook() {
    var url = encodeURI(this.url);
    var text = encodeURI(this.title);
    var URL = 'https://www.facebook.com/share.php?u=' + url + '&title=' + text;

    this._launchWindow(URL);
  }

  _pinterest() {
    var url = encodeURI(this.url);
    var text = encodeURI(this.title);
    var media = encodeURI(this.media);
    var URL = 'https://pinterest.com/pin/create/bookmarklet/?media=' + media + '&url=' + url + '&is_video=false&description=' + text;

    this._launchWindow(URL);
  }

  _google() {
    var url = encodeURI(this.url);
    var URL = 'https://plus.google.com/share?url=' + url;

    this._launchWindow(URL);
  }

  _linkedIn() {
    var url = encodeURI(this.url);
    var text = encodeURI(this.title);

    var source = this._getDomainName();

    var URL = 'http://www.linkedin.com/shareArticle?mini=true&url=' + url + '&title=' + text + '&source=' + source;

    this._launchWindow(URL);
  }

  _getDomainName() {
    var hostName = location.hostname;
    return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
  }

  _launchWindow(url) {
    window.open(url);
  }

  get title() {
    return this.getAttribute('title');
  }

  get url() {
    return this.getAttribute('url');
  }

  get media() {
    return this.getAttribute('media');
  }

}
customElements.define('social-buttons', SocialButtonsElement);