export default class LightGalleryElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {}

  _initialize() {}

  _initPlugin($this, index, data) {
    $this.lightGallery({
      dynamic: true,
      dynamicEl: data,
      index: index
    });
  }

  _createPlugin(index, data) {
    var $this = $(this);

    if ($this.data('lightGallery')) {
      $this.data('lightGallery').destroy(true);
      setTimeout(() => {
        this._initPlugin($this, index, data);
      }, 1000);
    } else {
      this._initPlugin($this, index, data);
    }
  }

  get jsonData() {
    if (this._json) return this._json;
    var data = this.getAttribute('json-data');

    if (data) {
      var json = JSON.parse(data);
      this._json = json;
      return json;
    } else {
      return null;
    }
  }

  show(index) {
    if (index === undefined) index = 0;
    var data = this.jsonData;

    this._createPlugin(index, data);
  }

}
customElements.define('light-gallery', LightGalleryElement);