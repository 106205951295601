import UtilityHelper from '../utility-helper/utility-helper';
export default class GoogleYoutubeElement extends HTMLElement {
  constructor() {
    super();
    this._isActive = false;
    this.youtubeContainer = null;
    this.youtubePlayer = null;
    this.youtubeThumbnail = null;
  }

  connectedCallback() {
    this.fire = UtilityHelper.fireEvent;

    this._attachDom();

    this._setEvents();
  }

  disconnectedCallback() {
    this._removeEvents();
  }

  _setProperties() {}

  _setEvents() {
    var thumbnail = this.youtubeThumbnail;
    thumbnail.addEventListener('click', this._onThumbnailClick.bind(this));
  }

  _setPlayerEvents() {}

  _removeEvents() {}

  _callIFrameApi() {
    var self = this;
    var videoId = this.videoId;
    this.player = 'test';
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = function () {
      self.player = new YT.Player('player', {
        height: '100%',
        width: '100%',
        videoId: videoId,
        events: {
          'onReady': self._onPlayerReady.bind(self),
          'onStateChange': self._onPlayerStateChange.bind(self)
        }
      });
    };
  }

  _setContainerDimensions() {
    var offsetHeight = this.youtubeContainer.offsetHeight;
    var offsetWidth = this.youtubeContainer.offsetWidth;
    this.youtubeContainer.style.height = offsetHeight + 'px';
    this.youtubeContainer.style.width = offsetWidth + 'px';
  }

  _attachDom() {
    var width = this.width;
    var height = this.height;
    var style = '';

    if (width && height) {
      var style = ' style="height:' + height + ';width:' + width + '"';
    }

    var thumbnail = this.thumbnail;
    var dom = '<div class="material-box">';
    dom += '<div id="youtubeContainer"' + style + '>';
    dom += '<img src="' + thumbnail + '" id="youtubeThumbnail">';
    dom += '<div id="player"></div>';
    dom += '</div>';
    dom += '</div>';
    var $dom = $(dom);
    $(this).append($dom);
    this.youtubeContainer = this.querySelector('#youtubeContainer');
    this.youtubePlayer = this.querySelector('#player');
    this.youtubeThumbnail = this.querySelector('#youtubeThumbnail');
  }

  _onThumbnailClick(event) {
    if (!this._isActive) {
      this._setContainerDimensions();

      this.youtubeContainer.classList.add('active');
      this.youtubeThumbnail.style.display = 'none';
      this._isActive = true;

      this._callIFrameApi();
    }
  }

  _onPlayerReady(event) {
    event.target.playVideo();
    this.fire('google-youtube-ready', {
      target: this
    });
  }

  _onPlayerStateChange(event) {
    this.fire('google-youtube-state-change', {
      data: event.data,
      target: this
    });
  }

  get width() {
    return this.getAttribute('width');
  }

  get height() {
    return this.getAttribute('height');
  }

  get thumbnail() {
    return this.getAttribute('thumbnail');
  }

  get thumbnailTitle() {
    return this.getAttribute('thumbnail-title');
  }

  get videoId() {
    return this.getAttribute('video-id');
  }

}
customElements.define('google-youtube', GoogleYoutubeElement);