import UtilityHelper from '../utility-helper/utility-helper';
export default class MDSelectElement extends HTMLElement {
  constructor() {
    super();
    this.select = null;
    this._onChange = this._onChange.bind(this);
  }

  static get observedAttributes() {
    return ['value', 'readonly', 'disabled'];
  }

  connectedCallback() {
    this.fire = UtilityHelper.fireEvent;
    setTimeout(() => {
      this._setEvents();
    }, 750);
  }

  disconnectedCallback() {
    this._removeEvents();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.select && oldValue) {
      this.select.setAttribute(name, newValue);

      if ((name === 'readonly' || name === 'disabled') && (newValue === 'false' || newValue === false)) {
        this.select.removeAttribute(name);
      }
    }
  }

  _setEvents() {
    var onChange = this._onChange;
    var select = this.querySelector('select');
    this.select = select;
    select.addEventListener('change', onChange);
  }

  _removeEvents() {
    var onChange = this._onChange;
    var select = this.select;
    select.removeEventListener('change', onChange);
  }

  _onChange(event) {
    this.select.classList.remove('placeholder-color');
    this.fire('md-select-change', {
      value: event.target.value,
      target: this
    });
  }

  get value() {
    return this.select.value;
  }

  set value(val) {
    this.select.value = val;
    this.setAttribute('value', val);
  }

  clear() {
    this.select.value = '';
  }

}
customElements.define('md-select', MDSelectElement);