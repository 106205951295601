export default class UtilityHelper {
  static get isTouch() {
    return 'ontouchend' in document;
  }

  static get clickEvent() {
    return 'ontouchend' in document ? 'touchend' : 'click';
  }

  static get tap() {
    if (this.isTouch) return 'tap';else return 'click';
  }

  static get viewport() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  static get isMediaTouch() {
    return window.innerWidth < 1025;
  }

  static get screenType() {
    var MQ_TOUCH_MAX_WIDTH = 1024; //var MQ_TOUCH_MIN_WIDTH = 320;

    var MQ_SMARTPHONE_MAX_WIDTH = 640; //var MQ_TABLET_MIN_WIDTH = 641;

    var width = this.viewport.width;
    if (width > MQ_TOUCH_MAX_WIDTH) return 'large';else if (width > MQ_SMARTPHONE_MAX_WIDTH) return 'medium';else return 'small';
  }

  static get guid() {
    var S4 = function S4() {
      return ((1 + Math.random()) * 65536 | 0).toString(16).substring(1);
    };

    return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
  }

  static random() {
    var length = arguments[0] === undefined ? 16 : arguments[0];
    var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";

    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];

    return result;
  }

  static id() {
    var length = arguments[0] === undefined ? 16 : arguments[0];
    var chars = "0123456789";
    var result = "";

    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];

    return result;
  }

  static isFunction(functionToCheck) {
    var getType = {};
    return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
  }

  static isEmptyObject(obj) {
    return Object.keys(obj).length == 0;
  }

  static get toast() {
    return document.querySelector('paper-toast');
  }

  static prependArrayElement(arr, element) {
    var clone = arr.slice(0);
    clone.unshift(element);
    return clone;
  }

  static prependArray(arr, arr2) {
    var clone = arr.slice(0);
    var clone2 = arr2.slice(0);
    return clone2.concat(clone);
  }

  static concatArray(arr, elements) {
    var clone = arr.slice(0);
    return clone.concat(elements);
  }

  static request(url, value, method, callback) {
    method = method.toLowerCase();
    let boolSendData = true;

    if (method === 'get' || method === 'delete') {
      boolSendData = false;
      if (value) url += '/' + value;
    } else if (method === 'post' || method === 'put') {
      value = JSON.stringify(value);
    }

    var req = new XMLHttpRequest();
    req.open(method, encodeURI(url));
    req.setRequestHeader('Content-Type', 'application/json');

    req.onload = function () {
      if (req.status === 200) {
        let data = JSON.parse(req.response);
        callback(null, data);
      } else {
        let statusText = req.statusText;
        callback({
          statusCode: req.status,
          message: req.statusText
        }, null);
      }
    };

    if (boolSendData) req.send(value);else req.send();
  }

  static viewportOffset(element) {
    var node = element,
        left = node.offsetLeft,
        top = node.offsetTop;
    node = node.parentNode;

    do {
      var styles = getComputedStyle(node);

      if (styles) {
        var position = styles.getPropertyValue('position');
        left -= node.scrollLeft;
        top -= node.scrollTop;

        if (/relative|absolute|fixed/.test(position)) {
          left += parseInt(styles.getPropertyValue('border-left-width'), 10);
          top += parseInt(styles.getPropertyValue('border-top-width'), 10);
          left += node.offsetLeft;
          top += node.offsetTop;
        }

        node = position === 'fixed' ? null : node.parentNode;
      } else {
        node = node.parentNode;
      }
    } while (node);

    return {
      left: left,
      top: top
    };
  }

  static parseQuery(url) {
    var query = {};
    var hu = url.split('?')[1];

    if (typeof hu !== 'undefined') {
      var gy = hu.split("&");

      for (i = 0; i < gy.length; i++) {
        var ft = gy[i].split("=");
        query[ft[0]] = ft[1];
      }
    }

    return query;
  }

  static fireEvent(name, data) {
    var event = new Event(name, {
      bubbles: true,
      cancelable: true,
      composed: true
    });
    event.detail = data;
    document.dispatchEvent(event);
  }

  static getDate(value) {
    value = value.split('T');
    value = value[0];
    value += 'T12:00:00';
    var date = new Date(value);
    var day = date.getDate().toString().length > 1 ? date.getDate() : '0' + date.getDate();
    var month = date.getMonth().toString().length > 1 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    var year = date.getFullYear();
    return year + '-' + month + '-' + day;
  }

  static get orientationEvent() {
    var supportsOrientationChange = "onorientationchange" in window,
        orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize';
    return orientationEvent;
  }

  static get browser() {
    var browser = {};
    browser.mozilla = false;
    browser.webkit = false;
    browser.opera = false;
    browser.msie = false;
    var nAgt = navigator.userAgent;
    browser.name = navigator.appName;
    browser.fullVersion = '' + parseFloat(navigator.appVersion);
    browser.majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix; // Opera

    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browser.opera = true;
      browser.name = "Opera";
      browser.fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1) browser.fullVersion = nAgt.substring(verOffset + 8);
    } // MSIE
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        browser.msie = true;
        browser.name = "Microsoft Internet Explorer";
        browser.fullVersion = nAgt.substring(verOffset + 5);
      } // Chrome
      else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
          browser.webkit = true;
          browser.name = "Chrome";
          browser.fullVersion = nAgt.substring(verOffset + 7);
        } // Safari
        else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
            browser.webkit = true;
            browser.name = "Safari";
            browser.fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf("Version")) != -1) browser.fullVersion = nAgt.substring(verOffset + 8);
          } // Firefox
          else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
              browser.mozilla = true;
              browser.name = "Firefox";
              browser.fullVersion = nAgt.substring(verOffset + 8);
            } // Other
            else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
                browser.name = nAgt.substring(nameOffset, verOffset);
                browser.fullVersion = nAgt.substring(verOffset + 1);

                if (browser.name.toLowerCase() === browser.name.toUpperCase()) {
                  browser.name = navigator.appName;
                }
              } else if (nAgt.indexOf('Mozilla') !== -1 && nAgt.indexOf('Firefox') === -1) {
                browser.msie = true;
                browser.name = "Internet Explorer";
                browser.fullVersion = '11';
              }

    if ((ix = browser.fullVersion.indexOf(";")) != -1) browser.fullVersion = browser.fullVersion.substring(0, ix);
    if ((ix = browser.fullVersion.indexOf(" ")) != -1) browser.fullVersion = browser.fullVersion.substring(0, ix);
    browser.majorVersion = parseInt('' + browser.fullVersion, 10);

    if (isNaN(browser.majorVersion)) {
      browser.fullVersion = '' + parseFloat(navigator.appVersion);
      browser.majorVersion = parseInt(navigator.appVersion, 10);
    }

    browser.version = browser.majorVersion;
    return browser;
  }

}