import UtilityHelper from '../utility-helper/utility-helper';
export default class MutationObserver {
  static get isRunning() {
    return this._isRunning;
  }

  static set isRunning(val) {
    this._isRunning = val;
  }

  static _onMutation(mutationSummary) {
    this._fireMutationEvent(MutationEvent);

    var summary = mutationSummary[0];

    this._fireMutationEvent(summary);

    if (!UtilityHelper.isEmptyObject(summary.attributeChanged)) {
      this._fireChangedMutationEvent(summary.attributeChanged);
    }

    if (summary.added && summary.added.length > 0) {
      this._fireAddedMutationEvent(summary.added);
    }

    if (summary.removed && summary.removed.length > 0) {
      this._fireRemovedMutationEvent(summary.removed);
    }
  }

  static _fireMutationEvent(summary) {
    UtilityHelper.fireEvent('document-mutation', summary);
  }

  static _fireChangedMutationEvent(changed) {
    UtilityHelper.fireEvent('document-mutation-changed', {
      changed: changed
    });
  }

  static _fireAddedMutationEvent(added) {
    UtilityHelper.fireEvent('document-mutation-added', {
      added: added
    });
  }

  static _fireRemovedMutationEvent(removed) {
    UtilityHelper.fireEvent('document-mutation-removed', {
      removed: removed
    });
  }

  static connect() {
    if (!this.isRunning) {
      this.isRunning = true;
      $(document).mutationSummary("connect", this._onMutation.bind(this), [{
        all: true
      }]);
    }
  }

  static disconnect() {
    this._running = false;
    $(document).mutationSummary('disconnect');
  }

}