import './app-drawer-layout';
import './app-drawer';
import './app-drawer-content';
import './app-toolbar';
import './app-header';
export default class AppLayoutElement extends HTMLElement {
  constructor() {
    super();
  }

}
customElements.define('app-layout', AppLayoutElement);